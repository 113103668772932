import { consola as l } from 'consola/basic';
import { backOff } from '../backoff';
import fcId from './../fcId'

export default function () {
    backOff(async () => {
        if (typeof window.googletag?.pubads === 'function') {
            l.success("Googletag loaded 😀");


            let id = await fcId.get();

            if (id) {
                l.info("Send fc id as publisher provider id:", id);
                googletag.pubads().setPublisherProvidedId(id);
            }

            return;
        }

        throw new Error("Googletag x not loaded, try again...");
    }, {
        maxDelay: 30_000,
        numOfAttempts: 10,
        startingDelay: 1000,
    }).catch(e => {
        l.error(e);
    });
}


