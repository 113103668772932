import {consola as l} from "consola/basic";

export default function () {
    const _comscore = _comscore || [];
    _comscore.push({c1: "2", c2: "36390024", options: {enableFirstPartyCookie: true}});

    const s = document.createElement("script");
    const el = document.getElementsByTagName("script")[0];
    s.async = true;
    s.src = "https://sb.scorecardresearch.com/cs/36390024/beacon.js";

    s.addEventListener('load', function () {
        l.success('Loaded ComScore library', s.src)
    });

    el.parentNode.insertBefore(s, el);
}