import {consola as l} from "consola/basic";

export default function () {


    (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({
            'gtm.start':
                new Date().getTime(), event: 'gtm.js'
        });
        var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-M3R8PW9');


    const tgmHead = document.getElementsByTagName("head")[0];
    const tgmScript = document.createElement("script");
    tgmScript.setAttribute("type", "text/javascript");
    tgmScript.async = true;
    tgmScript.setAttribute("src", "https://www.googletagmanager.com/gtag/js?id=G-H4MEPFEPQD");
    tgmScript.addEventListener('load', function () {
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            'functionality_storage': 'denied',
            'personalization_storage': 'denied',
            'security_storage': 'denied',
            'ad_user_data': 'denied',
            'ad_personalization': 'denied',
            'wait_for_update': 1500
        });
        gtag('set', 'ads_data_redaction', true);
        gtag('set', 'url_passthrough', true);


        gtag('js', new Date());
        gtag('config', 'G-H4MEPFEPQD');
    });

    tgmHead.appendChild(tgmScript);

    l.info('Load google tag manager')

    /*
    * Push datalayer info
    */
    if (window.dataLayerInfo && typeof window.dataLayerInfo === 'object') {
        window.dataLayer.push(window.dataLayerInfo);
        window.dataLayer.push({'userCountryCode': window.navigator.userLanguage || window.navigator.language});
    }

    window.dataLayer.push({'event': 'dataLayer.loaded'});
    window.dataLayer.push({'environment': window.location.host === 'www.calciomercato.com' ? 'prod' : 'staging'});
}
