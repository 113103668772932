import {consola as l} from "consola/basic";

export default function () {

    const e = document.getElementById("longitude-lib");

    if (!e) {
        return;
    }

    let src = e.getAttribute('content')

    const head = document.getElementsByTagName("head")[0];
    const script = document.createElement("script");

    script.setAttribute("type", "text/javascript");
    script.async = true;
    script.setAttribute("src", src);
    head.appendChild(script);

    l.info('Load longitude lib:', src)

}